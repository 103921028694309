import React, { Fragment, useState } from "react";

import { DeleteModal, ActivationModal } from "../Modals";

// custom hook
import useModuleActions from "hooks/useModuleActions";
// redux
import { useSelector } from "react-redux";

// utils
import {
  beneficaryListModuleId,
  claimFormModuleId,
  eventManagerModuleID,
  policyModuleId,
  predefinedModuleID,
  productionEventsId,
} from "utils/modulesIds";

import { allowedPrintPermissionStatuses, modalsStates } from "common/data";
import { SingleRowAction } from ".";
import ChatModal from "components/TP/Chat/ChatModal";
import { usePageType } from "hooks";

const RowActions = ({
  cellProps,
  cellProps: {
    row: { original: row },
  },
  onDelete,
  onActivate,
  onClickUpdate,
  onClickView,
  onClickStatus,
  onClickLog,

  onClickCopy,

  onClickPrint,
  subModuleId,
  removeUpdatePermission,
}) => {
  const { isViewPage } = usePageType();
  //extract the  actions permissions from the custom hook
  const {
    isUpdatePermission,
    isSoftDeletePermission,
    isActivatePermission,
    isLogFilePermission,
    isDeactivatePermission,
    isBlackListPermission,
    isWhiteListPermission,
    isUserActivityPermission,
    isCopyPermission,
    isViewPermission,

    isBatchUploadPermission,
    isPrintPermission,
  } = useModuleActions(subModuleId && subModuleId);

  // get the module id form global state

  const moduleId = useSelector((state) => state?.Module?.actions?.id);

  const isPredefinedModule = moduleId === predefinedModuleID;

  // states

  // to toggle the delete modal
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  // to toggle the activation modal
  const [isActivationModal, setIsActivationModal] = useState(false);

  const [isModal, setIsModal] = useState(false);
  // open delete modal
  const onClickDelete = () => {
    setIsDeleteModal(true);
  };

  // open activation modal
  const onClickActivate = () => {
    setIsActivationModal(true);
  };

  return (
    !isViewPage && (
      <Fragment>
        <div className="d-flex gap-2">
          {/* View icon */}

          {isViewPermission && (
            <SingleRowAction
              onClick={onClickView}
              iconClass="mdi mdi-eye"
              actionName={isViewPermission?.name || "View"}
              record={row}
            />
          )}

          {/* Edit icon */}

          {isUpdatePermission && !removeUpdatePermission && (
            <SingleRowAction
              onClick={onClickUpdate}
              color="text-primary"
              // iconClass="mdi mdi-pencil"
              iconClass="mdi mdi-eye"
              actionName={isUpdatePermission?.name}
              record={row}
            />
          )}

          {/* Deactivate Icon */}

          {isDeactivatePermission &&
            row?.is_active === 1 &&
            // hide the icon (in predefined Module) when group is null
            (isPredefinedModule ? (row?.group ? true : false) : true) && (
              <SingleRowAction
                onClick={onClickActivate}
                color="text-info"
                iconClass="mdi mdi-checkbox-marked-circle"
                actionName={isDeactivatePermission?.name}
                record={row}
              />
            )}

          {/* Activate Icon */}

          {isActivatePermission && row?.is_active === 0 && (
            <SingleRowAction
              onClick={onClickActivate}
              color="text-danger"
              iconClass="mdi mdi-checkbox-marked-circle"
              actionName={isActivatePermission?.name}
              record={row}
            />
          )}

          {/* Delete Icon */}

          {isSoftDeletePermission &&
            // hide the icon (in predefined Module) when group is null
            (isPredefinedModule ? (row?.group ? true : false) : true) &&
            // hide the delete icon in event manger and production events module when is_executed === 1
            (moduleId === eventManagerModuleID ||
            moduleId === productionEventsId
              ? !row.is_executed
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={onClickDelete}
                color="text-danger"
                iconClass="mdi mdi mdi-close"
                actionName={isSoftDeletePermission?.name}
                record={row}
              />
            )}

          {/* log history icon */}

          {isLogFilePermission && (
            <SingleRowAction
              onClick={onClickLog}
              // color="text-danger"
              iconClass="mdi mdi-history"
              actionName={isLogFilePermission?.name}
              record={row}
            />
          )}

          {(isPrintPermission ||
            (moduleId === beneficaryListModuleId &&
            row?.is_active &&
            !row?.group
              ? true
              : false)) &&
            (moduleId === claimFormModuleId
              ? allowedPrintPermissionStatuses.includes(row?.status?.id)
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={onClickPrint}
                // color="text-danger"
                iconClass="mdi mdi-cloud-print-outline"
                actionName={isPrintPermission?.name || "print contract"}
                record={row}
              />
            )}

          {/* User Activity  icon */}

          {isUserActivityPermission && (
            <SingleRowAction
              to={`/users/${row?.id}/activity`}
              target="_blank"
              iconClass="mdi mdi-account-search"
              actionName={isUserActivityPermission?.name}
              record={row}
            />
          )}

          {/* Black List icon */}

          {isBlackListPermission &&
            (row?.status?.id === 116 || !row?.status) &&
            // hide in the beneficiary is he is not active
            (moduleId === beneficaryListModuleId
              ? row?.is_active
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={onClickStatus}
                iconClass="mdi mdi-tooltip-remove"
                actionName={isBlackListPermission?.name}
                record={row}
              />
            )}

          {/* White List icon */}

          {isWhiteListPermission &&
            row?.status?.id === 115 &&
            // hide in the beneficiary is he is not active
            (moduleId === beneficaryListModuleId
              ? row?.is_active
                ? true
                : false
              : true) && (
              <SingleRowAction
                onClick={onClickStatus}
                color="text-danger"
                iconClass="mdi mdi-tooltip-check"
                actionName={isWhiteListPermission?.name}
                record={row}
              />
            )}

          {/* Copy (clone) icon */}

          {(isCopyPermission && moduleId !== policyModuleId) ||
            (moduleId === claimFormModuleId && (
              <SingleRowAction
                onClick={onClickCopy}
                iconClass="mdi mdi-content-copy"
                actionName={isCopyPermission?.name || "Copy"}
                record={row}
              />
            ))}

          {/* Batch Upload icon */}

          {isBatchUploadPermission && (
            <SingleRowAction
              to={`beneficiaries/${row?.id}/import`}
              iconClass="mdi mdi-cloud-upload"
              actionName={isBatchUploadPermission?.name}
              record={row}
            />
          )}
        </div>

        <DeleteModal
          show={isDeleteModal}
          onDeleteClick={() => {
            onDelete();
            setIsDeleteModal(false);
          }}
          onCloseClick={() => setIsDeleteModal(false)}
        />

        <ActivationModal
          show={isActivationModal}
          record={row}
          setIsActivationModal={setIsActivationModal}
          onConfirmClick={() => {
            onActivate();
            setIsActivationModal(false);
          }}
          onCloseClick={() => setIsActivationModal(false)}
        />

        {/* Chat Modal */}
        {isModal === modalsStates.chatModal && (
          <ChatModal
            show={isModal === modalsStates.chatModal}
            onCloseClick={() => setIsModal(false)}
            resourceId={row?.id}
            claim={row}
            beneficiary={row?.beneficiary}
          />
        )}
      </Fragment>
    )
  );
};

export default RowActions;
