import { motorPaths } from "common/data/routePaths";

import { lazy } from "react";

const FNOL = lazy(() => import("pages/Motors/FNOL"));
const FNOLForms = lazy(() => import("pages/Motors/FNOL/FNOLForms"));
const SearchResults = lazy(() =>
  import("pages/Motors/FNOL/SearchResults/SearchResults")
);

const FnolPrint = lazy(() => import("pages/Motors/FNOL/PDF/FnolPrint"));

// desctructure the paths
const { fnol } = motorPaths;

export const motorRoutes = [
  { path: fnol, component: FNOL },
  { path: `${fnol}/search-results`, component: SearchResults },
  // FNOL Forms
  // { path: `${fnol}/add`, component: FNOLForms },
  { path: `${fnol}/edit/:id`, component: FNOLForms },
  // { path: `${fnol}/view/:id`, component: FNOLForms },
  // { path: `${fnol}/print/:id`, component: FnolPrint },
];
