/*
This is Hook is used to Extract the permissions 
allowed on each Module for the logged in user
*/

import { useSelector } from "react-redux";

const useModuleActions = (subModuleId) => {
  const { actions, subModule, moduleId } = useSelector((state) => ({
    moduleId: state.Module?.id,
    actions: state.Module.actions,
    subModule: state.Module?.subModules?.find(
      (item) => item.id === subModuleId
    ),
  }));

  const extractSpecificAction = (module, actionId) => {
    if (
      module &&
      module.actions &&
      module.actions.find((action) => action.id === actionId)
    )
      // return true
      return module.actions.find((action) => action.id === actionId);
    else return false;
  };

  const isAddPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    1
  );
  // remove update in processsing claim form page
  const isUpdatePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    2
  );
  const isSoftDeletePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    3
  );
  const isActivatePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    4
  );
  const isLogFilePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    5
  );
  const isTableFilterPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    6
  );
  const isTableViewPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    7
  );
  // hide it for the subModules
  const isViewPermission = !subModuleId
    ? extractSpecificAction(actions, 8)
    : false;

  // give export permission to blacklisted report module by default
  const isTableExportPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    9
  );

  const isDeactivatePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    10
  );
  const isBlackListPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    16
  );
  const isWhiteListPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    17
  );
  const isUserActivityPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    18
  );

  const isViewDependencyPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    19
  );

  // hide it in the claim module
  const isCopyPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    20
  );

  const isBatchUploadPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    21
  );

  const isBeneficaryBenefitPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    23
  );
  const isBeneficaryViewPremiumPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    23
  );
  const isStandardCodePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    24
  );
  const isViewProcessingPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    25
  );

  const isAddClaimReimbursementPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    26
  );

  const isProductAddPolicyPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    27
  );
  const isPolicyAddBeneficiaryPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    28
  );
  const isBeneficiarySpecialNotesPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    29
  );
  const isPolicyRenewalPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    30
  );
  const isViewEndorsementPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    31
  );
  const isPrintPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    35
  );
  const isPrintInvoicePermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    38
  );
  const isValidationPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    40
  );
  const isInValidationPermission = extractSpecificAction(
    subModuleId ? subModule : actions,
    41
  );

  return {
    isAddPermission,
    isUpdatePermission,
    isSoftDeletePermission,
    isActivatePermission,
    isDeactivatePermission,
    isLogFilePermission,
    isTableFilterPermission,
    isTableViewPermission,
    isViewPermission,
    isTableExportPermission,
    isBlackListPermission,
    isWhiteListPermission,
    isUserActivityPermission,
    isViewDependencyPermission,
    isCopyPermission,
    isBatchUploadPermission,
    isBeneficaryViewPremiumPermission,
    isBeneficaryBenefitPermission,
    isStandardCodePermission,
    isViewProcessingPermission,
    isAddClaimReimbursementPermission,
    isProductAddPolicyPermission,
    isPolicyAddBeneficiaryPermission,
    isBeneficiarySpecialNotesPermission,
    isPolicyRenewalPermission,
    isViewEndorsementPermission,
    isPrintPermission,
    isPrintInvoicePermission,
    isValidationPermission,
    isInValidationPermission,
  };
};

export default useModuleActions;
